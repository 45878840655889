
import { Component, Vue, Watch } from 'nuxt-property-decorator'
import { Events } from '@pp-frontend/shared-packages/types/enum/Events'
import DocWidgetList from '@pp-frontend/shared-packages/components/Doc/WidgetList/DocWidgetList.vue'
import { ErrorValidation } from '~/types/ErrorValidation'
import SearchFormGrz from '~/components/search-form/SearchFormGrz.vue'
import SearchFormCtc from '~/components/search-form/SearchFormCtc.vue'
import SearchFormVu from '~/components/search-form/SearchFormVu.vue'
import SearchFormUin from '~/components/search-form/SearchFormUin.vue'

@Component({
  name: 'SearchForm',
  components: {
  SearchFormUin,
  SearchFormVu,
  SearchFormCtc,
  SearchFormGrz,
  DocWidgetList
  }
  })
export default class extends Vue {
  tabIndex: number = 1
  dataErrorValidations: any = {}
  errorDocVal: string = ''
  allowServices = this.$allowServices()
  toggle: boolean = false
  optionsList: any = [
    'Проверка по гос. номеру',
    'Проверка по СТС',
    'Проверка по ВУ',
    'Проверка по постановлению'
  ]

  mounted () {
    if (!this.allowServices.ctc_by_grz && this.tabIndex === 1) {
      this.tabIndex = 2
    }
    this.$eventBus.$on(Events.DATA_ERROR_FROM_VALIDATION, (error) => {
      this.dataErrorValidations = error
      this.errorDocVal = this.dataErrorValidations.error.response.data.error.document_value[0]
    })
  }

  @Watch('allowServices', { deep: true })
  allowServicesHandler () {
    if (!this.allowServices.ctc_by_grz && this.tabIndex === 1) {
      this.tabIndex = 2
    }
    return this.allowServices
  }

  getErrorFormValidations (type: string): Partial<ErrorValidation>|any {
    if (process.client && this.errorDocVal) {
      return type === this.dataErrorValidations.payerData[0].document_type
        ? {
            isError: true,
            msgError: this.errorDocVal
          }
        : {
            isError: false,
            msgError: ''
          }
    }

    return {
      isError: false,
      msgError: ''
    }
  }

  toggleDropdown () {
    this.toggle = !this.toggle
  }
}
