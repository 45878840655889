
import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component({
  components: {}
  })
export default class SearchFormTemplateUI extends Vue {
  @Prop({ default: 'margin-bottom: 3rem;' }) searchFormCardStyle!: string
  @Prop({ default: 'h1' }) titleTag: string
}
