
import { mask } from 'vue-the-mask'
import { Component } from 'nuxt-property-decorator'
import InputGrzAbstract from '@pp-frontend/shared-packages/classes/search-form/InputGrzAbstract'
@Component({
  directives: { mask }
  })
export default class extends InputGrzAbstract {
 plateNum: string = ''

 handleInput (event) {
   this.plateNum = event.target.value.toUpperCase()
   this.$emit('input', event.target.value.replace(/\s+/g, '').toUpperCase())
 }
}
