
import { Component, Vue } from 'nuxt-property-decorator'
import OfficialSource from '~/components/index/OfficialSource.vue'
import FeaturesIndex from '~/components/index/FeaturesIndex.vue'
import SearchForm from '~/components/search-form/SearchForm.vue'
import SearchFormTemplateUI from '~/components/UI/SearchFormTemplateUI.vue'

@Component({
  components: {
  SearchFormTemplateUI,
  SearchForm,
  FeaturesIndex,
  OfficialSource
  }
  })
export default class extends Vue {
}
