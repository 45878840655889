<template>
  <div class="how-to-pay-steps">
    <div class="how-to-pay-steps_header">
      <div class="how-to-pay-steps_header__divider" />
      <h2 class="how-to-pay-steps_header__title">
        Как найти и оплатить свои штрафы в три шага
      </h2>
    </div>
    <div class="how-to-pay-steps_steps">
      <div class="how-to-pay-steps_steps_step">
        <div class="how-to-pay-steps_steps_step_header">
          <p class="how-to-pay-steps_steps_step_header__number">
            01
          </p>
          <p class="how-to-pay-steps_steps_step_header__text">
            Введите гос. номер машины, СТС, номер прав или номер постановления
          </p>
        </div>
        <div class="how-to-pay-steps_steps_step_description">
          <p class="how-to-pay-steps_steps_step_description__text">
            По этим данным мы найдём все неоплаченные штрафы ГИБДД
          </p>
        </div>
      </div>
      <div class="how-to-pay-steps_steps__divider" />
      <div class="how-to-pay-steps_steps_step">
        <div class="how-to-pay-steps_steps_step_header">
          <p class="how-to-pay-steps_steps_step_header__number">
            02
          </p>
          <p class="how-to-pay-steps_steps_step_header__text">
            Мы проверим наличие взысканий по официальным базам данных
          </p>
        </div>
        <div class="how-to-pay-steps_steps_step_description">
          <p class="how-to-pay-steps_steps_step_description__text">
            Наш сервис обращается к официальным источникам, таким как ГИБДД и ГИС ГМП
          </p>
        </div>
      </div>
      <div class="how-to-pay-steps_steps__divider" />
      <div class="how-to-pay-steps_steps_step">
        <div class="how-to-pay-steps_steps_step_header">
          <p class="how-to-pay-steps_steps_step_header__number">
            03
          </p>
          <p class="how-to-pay-steps_steps_step_header__text">
            Оплатите найденные штрафы <br> со скидкой 25%
          </p>
        </div>
        <div class="how-to-pay-steps_steps_step_description">
          <p class="how-to-pay-steps_steps_step_description__text">
            У водителей есть 30 дней с даты вынесения постановления на оплату взыскания со скидкой
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
  .how-to-pay-steps {
    &_header {
      display: flex;
      align-items: center;
      gap: 10px;
      height: 35px;
      margin-bottom: 24px;
      @media (max-width:991px) {
        gap: 15px;
        height: 57px;
      }
      &__divider {
        min-width: 3px;
        height: 100%;
        background-color: #EE6F57;
        box-shadow: 0px 0px 8px 0px #EE6F5780;
        border-radius: 10px;
      }
      &__title {
        font-weight: 600;
        font-size: 20px;
        line-height: 135%;
        color: #3E3E3E;
        margin: 0;
        @media (max-width:991px) {
          font-size: 18px;
        }
      }
    }
    &_steps {
      display: flex;
      gap: 33px;
      @media (max-width:991px) {
        flex-direction: column;
        gap: 18px;
      }
      &_step {
        display: flex;
        flex-direction: column;
        gap: 12px;
        max-height: 165px;
        &_header {
          display: flex;
          flex-direction: column;
          gap: 12px;
          @media (max-width:991px) {
            align-items: center;
            flex-direction: row;
          }
          &__number {
            padding: 6px 8.5px;
            background-color: #145374;
            border-radius: 8px;
            font-weight: 600;
            font-size: 28px;
            line-height: 135%;
            color: #F9F9F9;
            width: fit-content;
            margin: 0;
            @media (max-width:991px) {
              padding: 6px 7.5px;
              font-size: 18px;
              height: fit-content;
            }
          }
          &__text {
            font-size: 16px;
            font-weight: 500;
            line-height: 150%;
            color: #145374;
            margin: 0;
            @media (max-width:991px) {
              font-size: 14px;
            }
          }
        }
        &_description {
          display: flex;
          gap: 7px;
          flex-direction: column;
          &__text {
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            color: #3E3E3E;
            margin: 0;
            @media (max-width:991px) {
              font-size: 14px;
            }
          }
        }
      }
      &__divider {
        width: 2px;
        height: 101px;
        background-color: #145374;
        opacity: .25;
        margin-top: auto;
        @media (max-width:991px) {
          display: none;
        }
      }
    }
  }
</style>
