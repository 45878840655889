
import { TheMask } from 'vue-the-mask'
import { Component, Prop } from 'nuxt-property-decorator'
import SearchFormAbstract from '@pp-frontend/shared-packages/classes/search-form/SearchFormAbstract'
import DocVu from '@pp-frontend/shared-packages/classes/doc-value/DocVu'
import { FieldsId } from '@pp-frontend/shared-packages/types/enum/FieldsId'
import InfoBtn from './InfoBtn.vue'
import { ErrorValidation } from '~/types/ErrorValidation'
import SearchEmailField from '~/components/search-form/SearchEmailField.vue'
import SearchFormOfertaText from '~/components/search-form/SearchFormOfertaText.vue'

@Component({
  components: {
  SearchFormOfertaText,
  SearchEmailField,
  InfoBtn,
  TheMask
  }
  })
export default class extends SearchFormAbstract {
  @Prop() dataErrorFormValidations!: ErrorValidation

  USER_EMAIL_REQUIRED = true
  formData = new DocVu()
  fieldId = FieldsId.VU
  isClientErrorValid: string = ''
  showTooltip = false
  plateNumPatternWithNums = /[0-9]|[АВЕКМНОРСТУХавекмнорстухABEKMHOPCTYXabekmhopctyx]/

  maskTokens = {
    '#': { pattern: /\d/ },
    F: {
      pattern: /[0-9АВЕКМНОРСТУХавекмнорстухABEKMHOPCTYXabekmhopctyx]/,
      transform (v: string) {
        const engLetters = 'ABEKMHOPCTYXabekmhopctyx'
        // eslint-disable
        const translations = {
          A: 'А',
          B: 'В',
          E: 'Е',
          K: 'К',
          M: 'М',
          H: 'Н',
          O: 'О',
          P: 'Р',
          C: 'С',
          T: 'Т',
          Y: 'У',
          X: 'Х'
        }
        if (engLetters.includes(v)) {
          return translations[v.toLocaleUpperCase()]
        }
        return v.toLocaleUpperCase()
      }
    }
  }

  handleMaskKeydown (e: KeyboardEvent) {
    const currentKey = e.key
    if (!currentKey || currentKey === 'Backspace') {
      return
    }
    if (!currentKey.match(this.plateNumPatternWithNums)) {
      this.showTooltip = true
    }
    /**
     * Обработка 4 символа в ву
     * если 3 буква, 4 тоже должен быть буквой
     * если 3 число, 4 тоже должен быть числом
     * иначе останавливаем обработку эвента
     */
    if (this.formData.document_value.length === 3) {
      const getKeyType = (v: string) => isNaN(parseInt(v)) ? 'string' : 'number'
      if (getKeyType(this.formData.document_value[2]) !== getKeyType(currentKey)) {
        this.showTooltip = true
        e.preventDefault()
      }
    }
  }

  errorClientValid () {
    this.isClientErrorValid = ''
    this.$v.$touch()

    if (!this.dataErrorFormValidations.isError) {
      // @ts-ignore
      if (this.$v.formData.document_value.$error) {
        this.isClientErrorValid = ' <span class="error-msg__item">00 АА 000000</span> <span class="error-msg__item">00 00 000000</span>'
      }
    }
  }

  getType (): string {
    return 'gibdd'
  }
}
