
import { Component, Vue } from 'nuxt-property-decorator'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
@Component({
  components: {
  Swiper,
  SwiperSlide
  }
  })
export default class extends Vue {
  active = 0
  swiperOption = {
    spaceBetween: 30,
    slidesPerView: 1.2,
    loop: true
  }

  get swiper () {
    // @ts-ignore
    return this.$refs.swiper.$swiper
  }

  slideChange (_swiper) {
    this.active = this.swiper.realIndex
  }

  scrollTop () {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }
}
