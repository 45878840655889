
import { Vue, Component, Prop, Emit } from 'nuxt-property-decorator'
import InfoBtn from '~/components/search-form/InfoBtn.vue'

@Component({
  components: { InfoBtn }
  })
export default class SearchEmailField extends Vue {
  @Prop() value: string | null
  @Prop({ default: false }) error?: boolean

  @Emit()
  input (_val: string) {

  }

  handleInput (e: Event) {
    this.input((e.target as HTMLInputElement).value)
  }
}
